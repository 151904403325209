
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.select-calendar__label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
}
.select-calendar__inner {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.select-calendar__item:not(:last-child) {
  margin-right: 5px;
}
.select-calendar__day {
  width: 95px;
}
.select-calendar__month {
  width: 135px;
}
.select-calendar__year {
  width: 115px;
}
